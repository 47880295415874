/* eslint-disable react/no-array-index-key */
import { Document, Font, Image, Link, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import OverpassBold from '../fonts/Overpass-Bold.ttf';
import OverpassRegular from '../fonts/Overpass-Regular.ttf';
import Secondary from '../img/kowalski.png';
import { Product } from '../types';
import MarkdownPDF from './MarkdownPDF';

Font.register({
  family: 'Overpass',
  fonts: [
    { src: OverpassRegular }, // Regular
    { src: OverpassBold, fontWeight: 'bold' }, // Bold
  ],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Overpass',
    fontSize: 14,
    paddingHorizontal: 10,
    paddingBottom: 100,
  },
  header: {
    display: 'flex',
    padding: 5,
    gap: 5,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: '1px',
    borderColor: '#f7f7f7',
    color: '#3A4A88',
  },
  titleSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 10,
  },
  imageSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f7f7f7',
    padding: 10,
    margin: 5,
    borderRadius: 6,
    maxHeight: 440,
    maxWidth: '100%',
  },
  section: {
    borderTopWidth: '1px',
    borderColor: '#f7f7f7',
    padding: 10,
  },
  footerSection: {
    position: 'absolute',
    left: 0,
    right: 0,
    width: '100%',
    padding: 10,
    bottom: 0,
    fontSize: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopWidth: '1px',
    borderColor: '#f7f7f7',
  },
  footerDivision: {
    display: 'flex',
    flexDirection: 'column',
    color: '#3A4A88',
  },
  link: {
    textDecoration: 'none',
    color: '#3A4A88',
  },
  table: {
    display: 'flex',
    width: 'auto',
    margin: 20,
    borderWidth: 1,
    borderColor: '#E0E0E0',
    borderBottomWidth: 0,
    borderRadius: 5,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#E0E0E0',
  },
  tableHeaderCell: {
    backgroundColor: '#f5f5f5',
    padding: 8,
    flex: 1,
    fontSize: 12,
    fontWeight: 'bold',
    borderRightWidth: 1,
    borderColor: '#E0E0E0',
  },
  tableBodyCell: {
    padding: 8,
    flex: 2,
    fontSize: 12,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 20,
    justifyContent: 'space-between',
    gap: 5,
  },
  column: {
    width: '50%',
    padding: 10,
  },
  row: {
    marginBottom: 8,
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  value: {
    fontSize: 12,
  },
});

interface TemplateProps extends Product {
  image: string;
}

const Template = ({ id, title, description, image, tags, features }: TemplateProps) => {
  const { t } = useTranslation();
  return (
    <Document>
      <Page style={styles.page}>
        {/* Header */}
        <Link style={styles.link} href="https://telme.com.ar" fixed>
          <View style={styles.header}>
            <Image src={Secondary} style={{ height: 30 }} />
            <Text style={{ fontWeight: 'bold', marginTop: 3 }}>INDUSTRIAS TELME</Text>
          </View>
        </Link>
        {/* Title */}
        <View style={styles.titleSection}>
          <Link
            style={{ fontSize: 22, fontWeight: 'bold', textDecoration: 'none', color: 'black' }}
            href={`https://telme.com.ar/product?id=${id}`}
          >
            {title}
          </Link>
          <Text style={{ fontSize: 16, textTransform: 'capitalize', marginTop: 5 }}>{tags}</Text>
          <Text style={{ color: 'gray', marginTop: 5 }}>{id}</Text>
        </View>

        {/* Image */}
        <View style={styles.imageSection}>
          <Image
            src={{ uri: image, method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
            style={{
              width: 'auto',
              height: 'auto',
              maxWidth: 500,
              maxHeight: 440,
              aspectRatio: 1,
            }}
          />
        </View>

        {/* Features */}
        <View style={styles.section}>
          {features?.main && (
            <>
              <Text style={{ fontSize: 20, fontWeight: 'bold' }}>
                {t('products.sections.features.main')}
              </Text>
              <View style={styles.table}>
                {features.main.map((feature, index) => (
                  <View style={styles.tableRow} key={index}>
                    <Text style={styles.tableHeaderCell}>{feature.header}</Text>
                    <Text style={styles.tableBodyCell}>{feature.body}</Text>
                  </View>
                ))}
              </View>
            </>
          )}
        </View>

        {/* Description */}
        <View style={styles.section}>
          <Text style={{ fontSize: 20, fontWeight: 'bold' }}>
            {t('products.sections.description')}
          </Text>
          <MarkdownPDF style={{ padding: 10 }}>
            {(description as string).replace(/\n\n/, '<BREAK>')}
          </MarkdownPDF>
        </View>

        {features?.other && (
          <View style={styles.section}>
            <Text style={{ fontSize: 16, fontWeight: 'bold' }}>
              {t('products.sections.features.other')}
            </Text>
            <View style={styles.container}>
              {features.other.map((feature, index) => (
                <View style={styles.row} key={index}>
                  <Text>
                    <Text style={styles.label}>{feature.header}: </Text>
                    <Text style={styles.value}>{feature.body}</Text>
                  </Text>
                </View>
              ))}
            </View>
          </View>
        )}

        {/* Footer */}
        <View style={styles.footerSection} fixed>
          <View style={styles.footerDivision}>
            <Link href="https://maps.app.goo.gl/UiCUtVoJHwQZRYsx8" style={styles.link}>
              <Text style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 3 }}>
                INDUSTRIAS TELME S.A.I.C.F.I.
              </Text>
              <Text>Calle 48 (ex Estrada) N° 1327 (1650)</Text>
              <Text>Villa Maipú, San Martín</Text>
              <Text>Provincia de Buenos Aires</Text>
              <Text>Argentina</Text>
            </Link>
          </View>
          <View style={styles.footerDivision}>
            <Text style={{ fontSize: 11, fontWeight: 'bold', marginBottom: 3 }}>
              Atención al cliente
            </Text>
            <Link style={styles.link} href="mailto:ventas@telme.com.ar">
              Correo: ventas@telme.com.ar
            </Link>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Link style={styles.link} href="tel:+5491147552811">
                Tel: (+54) 11-4755-2811
              </Link>
              <Text>/</Text>
              <Link style={styles.link} href="tel:+5491147559124">
                9124
              </Link>
              <Text>/</Text>
              <Link style={styles.link} href="tel:+5491147556861">
                6861
              </Link>
            </View>
            <Link
              style={styles.link}
              href={`https://wa.me/${process.env.WHATSAPP_NUMBER as string}`}
            >
              WhatsApp: (+54) 11-4428-1089
            </Link>
            <Text>Lunes a Viernes de 9:00 a 12:00hs y de 13:00 a 18:00.</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Template;
